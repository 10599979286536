import { Flex, Flexed, Input, MainWrap, PhoneNumber, Wrap } from '../../utilities/Shared'
import { useEffect, useState } from 'react'
import { MdDelete } from 'react-icons/md'
import { useNavigate, } from 'react-router-dom'
import { sortit, sortnum, sortsalesreps,sortcsrreps} from '../../utilities/Sort'


const CustomerPanel = ({vendorlist,search,setVendorinfo,setEditVendor,order,rowclass,setOrder,setList}) => {


    const nav = useNavigate()


  

  console.log(vendorlist)


  return (
    <>
    <Flexed style={{borderBottom:"1px solid black",padding:"4px",background:"#8398AB",color:"white",marginBottom:"5px"}} className="hand">
    <Flex flex=".8" onClick={e => sortnum('company_id',order,vendorlist,setList,setOrder)}> Id</Flex>
      <Flex flex="2" onClick={e => sortit('name',order,vendorlist,setList,setOrder)}>Customer</Flex>
      <Flex flex="1.4">Contact</Flex>
      <Flex>Email</Flex>
      <Flex flex="">Phone</Flex>
      <Flex flex="" onClick={e => sortsalesreps('salesrep',order,vendorlist,setList,setOrder)}>Sales Rep</Flex>
      <Flex flex="" onClick={e => sortcsrreps('custrep',order,vendorlist,setList,setOrder)}>CSR Rep</Flex>
      </Flexed >
    
      {vendorlist?.filter(item => {return search.toLowerCase() === '' ? item : item.name?.toLowerCase().includes(search.toLowerCase()) || item.company_id.toString().includes(search) || item.dba?.toLowerCase().includes(search)}).map((customer,index) =>{
            return <>
      
            <Flexed className="normalrow" key={index} margin="0"  borderBottom="1px solid lightgrey" >
            <Flex className="" flex=".8" onClick={e => nav(`customer/${customer._id}`)}>{customer.company_id}</Flex>
            <Flex className="" flex="2" onClick={e => nav(`customer/${customer._id}`)}>{customer.name} </Flex>
             <Flex className="" flex="1.4" onClick={e => nav(`customer/${customer._id}`)}>
    {
       customer.contacts.filter(it => it.contactid === customer.maincontact).map(o => o.name)

    }
              </Flex>
             <Flex className="" onClick={e => nav(`customer/${customer._id}`)}> {
       customer.contacts.filter(it => it.contactid === customer.maincontact).map(o => o.email)

    }</Flex>
             <Flex className="" onClick={e => nav(`customer/${customer._id}`)} flex="">{PhoneNumber(customer.mainphone)}</Flex>
             <Flex className="" onClick={e => nav(`customer/${customer._id}`)} flex="">{customer.salesrep?.firstName}&nbsp;{customer.salesrep?.lastName} </Flex>
             <Flex className="" onClick={e => nav(`customer/${customer._id}`)} flex="">{customer.custrep?.firstName}&nbsp;{customer.custrep?.lastName} </Flex>
          
            </Flexed>
           
      
            </>
         })}
         </>
  )
}

export default CustomerPanel