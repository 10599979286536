
import { createContext, useState, useEffect, useRef, useContext } from "react"
import axios from 'axios'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Cookies from 'js-cookie';
import { v4 as uuidv4 } from 'uuid';
import ChatContext from "./ChatContext";

export const JobticketContext = createContext();

export const JobticketProvider = ({children}) => {

  const { socket,users,sendsinglemessage,newmessagecount}  = useContext(ChatContext)





    // const[forms, setForms] = useState([])
    // const[jobinfo, setJobinfo] = useState([])
    // const[reset, setReset] = useState(false)
    // const[jobnum, setJobnum] = useState(0)
    // const[operations, setOperations] = useState([])
    // const[imposition, setImposition] = useState([])
    // const [employeelist, setEmployeelist] = useState()

    // const[oldjobinfo, setOldjobinfo] = useState({})
    // const[oldforms, setOldforms] = useState({})
    // const[old, setOld] = useState(false)
    // const[neww, setNeww] = useState(false)
    // const config = {
    //     headers: {
    //       'Content-Type': 'application/json',
    //       'Access-Control-Allow-Origin':'*' 
    //     }
    //   };
 




  return (
    <JobticketContext.Provider value={{socket,users}}>
         
        {children}
    </JobticketContext.Provider>
  )
}

export default JobticketContext