import axios from "axios"
import { useEffect, useState } from "react"
import { useNavigate, useParams } from "react-router-dom"
import { presses } from "../../utilities/Lists"
import { v4 as uuidv4 } from 'uuid';
import { MainWrap } from "../../utilities/Shared";

const Savejob = () => {

    const param = useParams()
    const[customerinfo, setCustomerinfo] = useState([])
    const[operations, setOperations] = useState([])
    const[dateentered, setDateentered] = useState()
    const[duedate, setDuedate] = useState()
    const[imposition, setImposition] = useState([])
    const[saveinfo, setSaveinfo] = useState(null)
    const[reset, setReset] = useState(false)
    const nav = useNavigate()
    const config = {
        headers: {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin':'*' 
        }
      };

      let r = {
        job:param.job
      }



   
    
useEffect(() => {

  const getimp = async() => {
    let r = {
      job:param.job
    }
   await axios.post(`https://highgroundsoftware.com/dash/dashboard/php/getjobbyjobnumber.php`,r,config)
   //await axios.post(`https://highgroundsoftware.com/dash/dashboard/php/getjobimposition.php`,r,config)
    .then(res => {
      console.log(res.data)
      setImposition(res.data); 
         
        load()
        getb()
  ;})
    .catch(err => console.log(err))
   }
   getimp()

 
  

//    setTimeout(function(){
         
//     setReset(!reset)
// },4000);
},[param,reset])
  
  
const getb = async() => {
    await axios.post('https://highgroundsoftware.com/dash/dashboard/php/getjobcustomerbyjob.php',r,config)
  .then(res => {
    console.log(res.data)
    setCustomerinfo(res.data[0]);setDateentered(new Date(res.data[0].dateentered));
    let u = new Date(res.data[0].duedate)
     u.setDate(u.getDate()+1); 
    setDuedate(u)
  
  })
  .catch(error => { console.log({...error});})
  }
  const getops = async() => {
    await axios.post(`https://highgroundsoftware.com/dash/dashboard/php/getalloperations.php`,r,config)
   .then(res => {setOperations(res.data);})
   .catch(err => console.log(err))
   }

  //  const getimp = async() => {
  //   let r = {
  //     job:param.job
  //   }
  //  //await axios.post(`https://highgroundsoftware.com/dash/dashboard/php/getjobbyjobnumber.php`,r,config)
  //  await axios.post(`https://highgroundsoftware.com/dash/dashboard/php/getjobimposition.php`,r,config)
  //   .then(res => {
  //     console.log(res.data)
  //     setImposition(res.data); 
         
  //       load()
  // ;})
  //   .catch(err => console.log(err))
  //  }




   
   
   
   const load = () => {

    console.log(setforms(imposition))
    let y = {
      ...customerinfo,
forms:setforms(imposition),
imposition:imposition,
allocated:"",
pullcut:""

    }
    console.log(y)
  // if(y.forms?.length > 0){
 saveit(y)

   //}
    
   }






   
    const saveit = async(y) => {
        console.log(y)
  await axios.post('https://highgroundapi.herokuapp.com/jobs/add',y)
 //await axios.post('http://localhost:5003/jobs/add',y)
        .then(res => {console.log('ttt');go()}) 
        .catch(err => console.log(err))

      
      }
     
const go = () => {
 nav(`/jobticket/good/${param.job}`,{ replace: true })
}
  

   const setforms = (imposition) => {
    console.log(imposition)
 
    const madeforms = []
    imposition?.map((item,index) => {
    if(parseInt(item.numforms) > 1 && !isDigital(item.press)){
      let i
      let n = 1
     
      
      for(i=1;i<=item?.numforms;i++){
      
          const r = {
            item:item.id,
            totalpages:item.pages,
            pages:item.pages / item.numforms,
            numup:item.numup,
            versions:item.versions,
            pressid:item.press,
        pressname:getpress(item.press),
           width:item.sheetw,
           length:item.sheetl,
           method:item.method,
           numforms:item.numforms,
           name:`${n}) ${item.component}`,
           paper:item.paper,
           fink:item.fink,
           bink:item.bink,
           faq:item.faq,
           baq:item.baq,
           totalinkused:item.totalinkused,
         processtext:changetext(item.press),
    formid:uuidv4(),
    originalform:item.component,
    originalformid:item.id,
    digital:isDigital(item.press),
    stockid:item.paperid,
   // allocated:isAllocted(item.paperid),
    partofid:`${item.id}${n}`,
   //prepressStatus:getstatus(`${item.id}${n}`,'prepress','multi') || "Incoming",
  // pressStatus:getstatus(`${item.id}${n}`,'press','multi',isDigital(item.press)),
    sett:'multi',
    topress:multitopress(item.mr,item.additionalmr,item.needed,item.mroverride,item.numup,item.versions,item.numforms)
          }                                       
          madeforms.push(r)
          n++ 
         
              }   
              
    }else{
    
      const t = {
        item:item.id,
        totalpages:item.pages,
        pages:item.pages / item.numforms,
        numup:item.numup,
        versions:item.versions,
        pressid:item.press,
    pressname:getpress(item.press),
       width:item.sheetw,
       length:item.sheetl,
       method:item.method,
       numforms:item.numforms,
       name:`${item.component}`,
       paper:item.paper,
       fink:item.fink,
       bink:item.bink,
       faq:item.faq,
       baq:item.baq,
       totalinkused:item.totalinkused || "",
       processtext:changetext(item.press),
       formid:uuidv4(),
    originalform:item.component,
    originalformid:item.id,
  digital:isDigital(item.press),
    stockid:item.paperid,
    //allocated:isAllocted(item.paperid),
    partofid:0, 
   // prepressStatus:getstatus(`${item.id}`,'prepress','single')  || "Incoming",
   // pressStatus:getstatus(`${item.id}`,'press','single',isDigital(item.press)),
    sett:'single',
    topress:singletopress(item.mr,item.additionalmr,item.needed,item.mroverride,item.numup,item.versions,isDigital(item.press))
      }
      madeforms.push(t)
    }
    console.log(madeforms)
  
    })
  
    return madeforms 
  }


  const isDigital = (pressid) => {
    if(pressid == 9 || pressid == 7 || pressid == 8 || pressid == 10){
    return true
    }else{
      return false
    }
    }

    const changetext = (item) => {

        let text
      switch(item){
        case "9":
          text= "Colormax"
          break;
        case "8":
        case "7":
        case "10":
          text= "Digital"
           break;
        case "1":
        case "2":
        case "3":
        case "4":
        case "5":
        case "6":
          text= "Press"
          break;
      }
      return text
      }

  const getpress = (id) => {

    let y = presses?.filter(filt => filt.id == id)
    let r = y[0]?.name
    return r
  }

 

    const multitopress = (mr,additionalmr,needed,mroverride,numup,versions,forms) =>{
    
      let m = mroverride > 0 ? parseInt(mroverride) / parseInt(forms): parseInt(mr) / parseInt(forms)
      let to =  ((parseInt(needed) + parseInt(mr) + parseInt(additionalmr)) * parseInt(versions))  / parseInt(forms)
      let off = (parseInt(to) -parseInt(m)) 
      let totm = mroverride > 0 ? parseInt(mroverride) : parseInt(mr)
      let totto =  (parseInt(needed) + parseInt(mr) + parseInt(additionalmr)) * parseInt(numup) * parseInt(versions) 
      let totoff = parseInt(totto) -parseInt(totm)
       /* $poundset = $c['sheetl'] * $c['sheetw'] * $paper['weight'] * 2;
        $poundset = ceil($poundset / $paper['divide']);
        $mradded = $topress/$c['parentout'];
         $sneed = number_format(ceil(($poundset * $mradded) / 1000));
         */
      let y = {
       totm,
       totto,
       totoff,
        mr:m,
        to,
        off
      }
      return y
      }


    const singletopress = (mr,additionalmr,needed,mroverride,numup,versions,digital) =>{


      let m = mroverride > 0 ? parseInt(mroverride) : parseInt(mr)
      let to =  ((parseInt(needed) + parseInt(m) + parseInt(additionalmr)))
      let off = parseInt(to) -parseInt(m)

      if(digital){

      }
       /* $poundset = $c['sheetl'] * $c['sheetw'] * $paper['weight'] * 2;
        $poundset = ceil($poundset / $paper['divide']);
        $mradded = $topress/$c['parentout'];
         $sneed = number_format(ceil(($poundset * $mradded) / 1000));
         */
      let y = {
 
        mr:m,
        to,
        off
      }
      return y
      }


//console.log(customerinfo)
//console.log(operations)
//console.log(imposition)
//console.log()
  return (
    <MainWrap>

<span style={{fontSize:"calc(2.4rem + .2vw)",color:"green"}}>Configuring Job. Please wait one moment</span>


   </MainWrap>
  )
}

export default Savejob



